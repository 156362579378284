import React, {Component} from 'react';
import axios from 'axios';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPublic from '../../components/Layout/Public';
import {setTitle} from '../../util/common';

export default class Login extends Component {
	state = {
		ready: false,
		success: false,
		proceed: false,
		error: false,
		email: '',
		password: ''
	};

	componentDidMount() {
		setTitle(['Logowanie']);

		if (window.localStorage.getItem('token')) {
			window.location.replace('/charity_action');
		} else {
			this.setState({
				ready: true
			});
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {email, password, proceed} = this.state;
		if (proceed) return false;

		if (email === '' || password === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		const data = {
			email,
			password
		};

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/user/login`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					window.localStorage.setItem('token', res.message);

					setTimeout(() => {
						window.location.href = '/charity_action';
					}, 1500);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			ready, success, proceed, error
		} = this.state;

		if (!ready) {
			return (
				<LayoutPublic>
					<Row>
						<Col md={{span: 6, offset: 3}}>
							<h2 style={{marginBottom: '2rem'}}>System crowdfundingu</h2>
							<Alert variant="warning">
								Trwa ładowanie zasobów...
							</Alert>
						</Col>
					</Row>
				</LayoutPublic>
			);
		}

		return (
			<LayoutPublic>
				<Row>
					<Col md={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Logowanie</h2>
						{
							error && (
								<Alert variant="danger">
									Popraw dane do logowania.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Logowanie w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Zalogowano, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Form.Group className="mb-3" controlId="email">
								<Form.Label>E-mail</Form.Label>
								<Form.Control name="email" type="email" placeholder="Wpisz e-mail" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group className="mb-3" controlId="password">
								<Form.Label>Hasło</Form.Label>
								<Form.Control name="password" type="password" placeholder="Wpisz hasło" onChange={this.handleChange} />
							</Form.Group>

							<Button variant="primary" type="submit">
								Zaloguj się
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPublic>
		);
	}
}
