import React, {Component} from 'react';
import axios from 'axios';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPublic from '../../components/Layout/Public';
import {setTitle} from '../../util/common';

export default class ForgotPassword extends Component {
	state = {
		ready: false,
		success: false,
		proceed: false,
		error: false,
		email: ''
	};

	componentDidMount() {
		setTitle(['Przywracanie hasła']);

		if (window.localStorage.getItem('token')) {
			window.location.replace('/charity_action');
		} else {
			this.setState({
				ready: true
			});
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {email, proceed} = this.state;
		if (proceed) return false;

		if (email === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/user/restore/${email}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					setTimeout(() => {
						window.location.href = '/';
					}, 1500);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			ready, success, proceed, error
		} = this.state;

		if (!ready) {
			return (
				<LayoutPublic>
					<Row>
						<Col md={{span: 6, offset: 3}}>
							<h2 style={{marginBottom: '2rem'}}>System crowdfundingu</h2>
							<Alert variant="warning">
								Trwa ładowanie zasobów...
							</Alert>
						</Col>
					</Row>
				</LayoutPublic>
			);
		}

		return (
			<LayoutPublic>
				<Row>
					<Col md={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Przywracanie hasła</h2>
						{
							error && (
								<Alert variant="danger">
									Popraw dane do przywrócenia hasła.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Generowanie nowego hasła w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Gotowe, sprawdź swoją skrzynkę email, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Form.Group className="mb-3" controlId="email">
								<Form.Label>E-mail</Form.Label>
								<Form.Control name="email" type="email" placeholder="Wpisz e-mail" onChange={this.handleChange} />
							</Form.Group>

							<Button variant="primary" type="submit">
								Przypomnij hasło
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPublic>
		);
	}
}
