import React, {Component} from 'react';
import axios from 'axios';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, generateCharityType, setTitle} from '../../../util/common';

class EditCharity extends Component {
	state = {
		charityActions: null,
		successFetch: false,
		proceedFetch: false,
		errorFetch: false,
		successCharityActionsFetch: false,
		proceedCharityActionsFetch: false,
		errorCharityActionsFetch: false,
		errorCharityTypesFetch: false,
		proceedCharityTypesFetch: false,
		successCharityTypesFetch: false,
		success: false,
		proceed: false,
		error: false,
		charity: null,
		email: '',
		price: '',
		created: null,
		charityActionId: null,
		charityTypeId: null
	};

	componentDidMount() {
		extendToken();

		const {charityActionId, charityId} = this.props.match.params;

		setTitle(['Zbiórki', `Zbiórka #${charityActionId}`, `Wpłata #${charityId}`, 'Edytuj']);

		this.loadData();
		this.loadChaityActions();
		this.loadChaityTypes();
	}

	loadData = () => {
		this.setState({
			errorFetch: false,
			proceedFetch: true,
			successFetch: false
		});

		const {charityId} = this.props.match.params;

		axios.get(`${process.env.REACT_APP_API_URL}/charity/${charityId}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorFetch: false,
						proceedFetch: false,
						successFetch: true
					});

					this.setState({
						charity: res.message,
						email: res.message.sponsor_email,
						price: res.message.price,
						created: res.message.created.split(' ')[0],
						charityActionId: res.message.charity_action_id,
						charityTypeId: res.message.charity_type_id
					});

					setTimeout(() => {
						this.setState({
							successFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorFetch: true,
						proceedFetch: false,
						successFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorFetch: true,
					proceedFetch: false,
					successFetch: false
				});
			});

		return true;
	};

	loadChaityActions = () => {
		this.setState({
			errorCharityActionsFetch: false,
			proceedCharityActionsFetch: true,
			successCharityActionsFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/charity_action/`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorCharityActionsFetch: false,
						proceedCharityActionsFetch: false,
						successCharityActionsFetch: true
					});

					this.setState({
						charityActions: res.message
					});

					setTimeout(() => {
						this.setState({
							successCharityActionsFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorCharityActionsFetch: true,
						proceedCharityActionsFetch: false,
						successCharityActionsFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorCharityActionsFetch: true,
					proceedCharityActionsFetch: false,
					successCharityActionsFetch: false
				});
			});

		return true;
	};

	loadChaityTypes = () => {
		this.setState({
			errorCharityTypesFetch: false,
			proceedCharityTypesFetch: true,
			successCharityTypesFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/charity/type`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorCharityTypesFetch: false,
						proceedCharityTypesFetch: false,
						successCharityTypesFetch: true
					});

					this.setState({
						charityTypes: res.message
					});

					setTimeout(() => {
						this.setState({
							successCharityTypesFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorCharityTypesFetch: true,
						proceedCharityTypesFetch: false,
						successCharityTypesFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorCharityTypesFetch: true,
					proceedCharityTypesFetch: false,
					successCharityTypesFetch: false
				});
			});

		return true;
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			email,
			price,
			created,
			charityActionId,
			charityTypeId,
			proceed,
			charity
		} = this.state;
		if (proceed) return false;

		if (price === '' || email === '' || created === '' || charityActionId === '' || charityTypeId === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		const {
			charityId
		} = this.props.match.params;

		const data = {
			charity_action_id: charityActionId,
			charity_type_id: charityTypeId,
			price,
			email,
			created
		};

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/charity/${charityId}`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					setTimeout(() => {
						window.location.replace(`/charity/${charity.charity_action_id}`);
					}, 2000);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			charityId
		} = this.props.match.params;

		const {
			charityActions,
			success,
			proceed,
			error,
			errorFetch,
			proceedFetch,
			successFetch,
			errorCharityActionsFetch,
			proceedCharityActionsFetch,
			successCharityActionsFetch,
			errorCharityTypesFetch,
			proceedCharityTypesFetch,
			successCharityTypesFetch,
			email,
			price,
			created,
			charityActionId,
			charityTypeId,
			charityTypes
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>
							Edycja wpłaty #{charityId}
						</h2>
						{
							(errorFetch || errorCharityTypesFetch || errorCharityActionsFetch) && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							(proceedFetch || proceedCharityTypesFetch || proceedCharityActionsFetch) && (
								<Alert variant="warning">
									Generowanie widoku w toku... System może częściowo nie odpowiadać.
								</Alert>
							)
						}
						{
							(successFetch && successCharityTypesFetch && successCharityActionsFetch) && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						{
							error && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Aktualizowanie wpłaty w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Wpłata zaktualizowana, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Form.Group className="mb-3" controlId="email">
								<Form.Label>E-mail sponsora</Form.Label>
								<Form.Control defaultValue={email} name="email" type="email" placeholder="Wpisz e-mail sponsora" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group className="mb-3" controlId="price">
								<Form.Label>Kwota (zł)</Form.Label>
								<Form.Control defaultValue={price} name="price" type="number" placeholder="Wprowadź kwotę" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group className="mb-3" controlId="created">
								<Form.Label>Data wpłaty</Form.Label>
								<Form.Control defaultValue={created} name="created" type="date" placeholder="Wprowadź datę" onChange={this.handleChange} />
							</Form.Group>

							<Form.Group className="mb-3" controlId="charityActionId">
								<Form.Label>Zbiórka</Form.Label>
								<Form.Control name="charityActionId" as="select" onChange={this.handleChange}>
									<option value="">Wybierz zbiórkę</option>
									{
										charityActions && charityActions.map((ca) => (
											<option key={`charity_action_${ca.id}`} value={ca.id} selected={charityActionId === ca.id}>{ca.name}</option>
										))
									}
								</Form.Control>
							</Form.Group>

							<Form.Group className="mb-3" controlId="charityTypeId">
								<Form.Label>Rodzaj wpłaty</Form.Label>
								<Form.Control name="charityTypeId" as="select" onChange={this.handleChange}>
									<option value="">Wybierz rodzaj wpłaty</option>
									{
										charityTypes && charityTypes.map((ct) => (
											<option key={`charity_type_${ct.id}`} value={ct.id} selected={charityTypeId === ct.id}>{generateCharityType(ct.name)}</option>
										))
									}
								</Form.Control>
							</Form.Group>

							<Button variant="primary" type="submit">
								Zapisz tą wpłatę
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditCharity.propTypes = {
	match: PropTypes.object.isRequired
};

export default EditCharity;
