import React, {Component} from 'react';
import axios from 'axios';
import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class AddIntegration extends Component {
	state = {
		success: false,
		proceed: false,
		error: false,
		name: '',
		integrationModeTypeName: '',
		clientId: '',
		crc: '',
		report: ''
	};

	componentDidMount() {
		extendToken();

		setTitle(['Integracje', 'Dodaj nową integrację']);
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			name,
			integrationModeTypeName,
			clientId,
			crc,
			report,
			proceed
		} = this.state;
		if (proceed) return false;

		if (name === '' || integrationModeTypeName === '' || clientId === '' || crc === '' || report === '') {
			this.setState({
				error: true,
				proceed: false,
				success: false
			});

			return false;
		}

		const data = {
			name,
			integration_mode_type_name: integrationModeTypeName,
			client_id: clientId,
			crc,
			report
		};

		this.setState({
			error: false,
			proceed: true,
			success: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/integration/add`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						error: false,
						proceed: false,
						success: true
					});

					setTimeout(() => {
						window.location.replace('/integration');
					}, 2000);
				} else {
					this.setState({
						error: true,
						proceed: false,
						success: false
					});
				}
			})
			.catch(() => {
				this.setState({
					error: true,
					proceed: false,
					success: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			success, proceed, error, integrationModeTypeName
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>Nowa integracja</h2>
						{
							error && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceed && (
								<Alert variant="warning">
									Zakładanie integracji w toku...
								</Alert>
							)
						}
						{
							success && (
								<Alert variant="success">
									Integracja utworzona, trwa przekierowanie...
								</Alert>
							)
						}
						<Form onSubmit={this.handleSubmit}>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>Nazwa integracji</Form.Label>
								<Form.Control name="name" type="text" placeholder="Wpisz nazwę integracji" onChange={this.handleChange} />
							</Form.Group>

							<Row>
								<Col lg={{span: 6}}>
									<Form.Group className="mb-3" controlId="integrationModeTypeName">
										<Form.Label>Tryb</Form.Label>
										<Form.Control name="integrationModeTypeName" as="select" onChange={this.handleChange}>
											<option value="">Wybierz tryb integracji</option>
											<option value="DEVELOPMENT" selected={integrationModeTypeName === 'DEVELOPMENT'}>DEVELOPMENT</option>
											<option value="PRODUCTION" selected={integrationModeTypeName === 'PRODUCTION'}>PRODUCTION</option>
										</Form.Control>
									</Form.Group>
								</Col>
								<Col lg={{span: 6}}>
									<Form.Group className="mb-3" controlId="client_id">
										<Form.Label>Identyfikator klienta</Form.Label>
										<Form.Control name="clientId" type="text" placeholder="Wpisz identyfikator klienta" onChange={this.handleChange} />
									</Form.Group>
								</Col>
							</Row>

							<Row>
								<Col lg={{span: 6}}>
									<Form.Group className="mb-3" controlId="crc">
										<Form.Label>Wartość CRC</Form.Label>
										<Form.Control name="crc" type="text" placeholder="Wpisz wartość CRC" onChange={this.handleChange} />
									</Form.Group>
								</Col>
								<Col lg={{span: 6}}>
									<Form.Group className="mb-3" controlId="report">
										<Form.Label>Wartość REPORT</Form.Label>
										<Form.Control name="report" type="text" placeholder="Wpisz wartość REPORT" onChange={this.handleChange} />
									</Form.Group>
								</Col>
							</Row>

							<Button variant="primary" type="submit">
								Utwórz nową integrację
							</Button>
						</Form>
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

export default AddIntegration;
