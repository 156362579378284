import React, {Component} from 'react';
import axios from 'axios';

import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class ProfileAccount extends Component {
	state = {
		initialized: false,
		successFetch: false,
		proceedFetch: false,
		errorFetch: false,
		successUpdate: false,
		proceedUpdate: false,
		errorUpdate: false,
		email: '',
		password: '',
		firstname: '',
		lastname: ''
	};

	componentDidMount() {
		extendToken();

		setTitle(['Twoje konto']);

		this.setState({
			errorFetch: false,
			proceedFetch: true,
			successFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/account/me`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorFetch: false,
						proceedFetch: false,
						successFetch: true
					});

					this.setState({
						initialized: true,
						email: res.message.email,
						firstname: res.message.firstname,
						lastname: res.message.lastname
					});

					setTimeout(() => {
						this.setState({
							successFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorFetch: true,
						proceedFetch: false,
						successFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorFetch: true,
					proceedFetch: false,
					successFetch: false
				});
			});

		return true;
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			email, password, firstname, lastname, proceedUpdate, proceedFetch
		} = this.state;
		if (proceedUpdate || proceedFetch) return false;

		if (email === '') {
			this.setState({
				errorUpdate: true,
				proceedUpdate: false,
				successUpdate: false
			});

			return false;
		}

		let data = null;

		if (password === '') {
			data = {
				email,
				firstname,
				lastname
			};
		} else {
			data = {
				email,
				password,
				firstname,
				lastname
			};
		}

		this.setState({
			errorUpdate: false,
			proceedUpdate: true,
			successUpdate: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/account/update`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorUpdate: false,
						proceedUpdate: false,
						successUpdate: true
					});

					setTimeout(() => {
						window.location.replace('/account');
					}, 2000);
				} else {
					this.setState({
						errorUpdate: true,
						proceedUpdate: false,
						successUpdate: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorUpdate: true,
					proceedUpdate: false,
					successUpdate: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			initialized, firstname, lastname, email, successFetch, proceedFetch, errorFetch, successUpdate, proceedUpdate, errorUpdate
		} = this.state;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 6, offset: 3}}>
						<h2 style={{marginBottom: '2rem'}}>Zaktualizuj swoje konto użytkownika</h2>
						{
							errorFetch && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							proceedFetch && (
								<Alert variant="warning">
									Trwa ładowanie danych, daj systemowi trochę czasu :)
								</Alert>
							)
						}
						{
							successFetch && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						{
							errorUpdate && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceedUpdate && (
								<Alert variant="warning">
									Aktualizowanie konta w toku...
								</Alert>
							)
						}
						{
							successUpdate && (
								<Alert variant="success">
									Konto zostało zaktualizowane, trwa przekierowanie...
								</Alert>
							)
						}
						{
							initialized && (
								<Form onSubmit={this.handleSubmit}>
									<Row>
										<Col lg={{span: 6}}>
											<Form.Group className="mb-3" controlId="firstname">
												<Form.Label>Imię</Form.Label>
												<Form.Control defaultValue={firstname} name="firstname" type="text" placeholder="Wpisz imię" onChange={this.handleChange} />
											</Form.Group>
										</Col>
										<Col lg={{span: 6}}>
											<Form.Group className="mb-3" controlId="lastname">
												<Form.Label>Nazwisko</Form.Label>
												<Form.Control defaultValue={lastname} name="lastname" type="text" placeholder="Wpisz nazwisko" onChange={this.handleChange} />
											</Form.Group>
										</Col>
									</Row>

									<Form.Group className="mb-3" controlId="email">
										<Form.Label>E-mail</Form.Label>
										<Form.Control defaultValue={email} name="email" type="email" placeholder="Wpisz e-mail" onChange={this.handleChange} />
									</Form.Group>

									<Form.Group className="mb-3" controlId="password">
										<Form.Label>Hasło</Form.Label>
										<Form.Control name="password" type="password" placeholder="Wpisz hasło tylko, gdy zmieniasz" onChange={this.handleChange} />
									</Form.Group>

									<Button variant="primary" type="submit">
										Zapisz zmiany
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

export default ProfileAccount;
