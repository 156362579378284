import React, {Component} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
	Form, Button, Row, Col, Alert
} from 'react-bootstrap';
import LayoutPrivate from '../../../components/Layout/Private';

import {extendToken, setTitle} from '../../../util/common';

class EditIntegration extends Component {
	state = {
		initialized: false,
		successFetch: false,
		proceedFetch: false,
		errorFetch: false,
		successUpdate: false,
		proceedUpdate: false,
		errorUpdate: false,
		name: '',
		integrationModeTypeName: '',
		clientId: '',
		crc: '',
		report: ''
	};

	componentDidMount() {
		extendToken();

		const {integrationId} = this.props.match.params;

		setTitle(['Integracje', `Integracja #${integrationId}`, 'Edytuj']);

		this.setState({
			errorFetch: false,
			proceedFetch: true,
			successFetch: false
		});

		axios.get(`${process.env.REACT_APP_API_URL}/integration/${integrationId}`)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorFetch: false,
						proceedFetch: false,
						successFetch: true
					});

					this.setState({
						initialized: true,
						name: res.message.name,
						integrationModeTypeName: res.message.integration_mode_type_name,
						clientId: res.message.client_id,
						crc: res.message.crc,
						report: res.message.report
					});

					setTimeout(() => {
						this.setState({
							successFetch: false
						});
					}, 2500);
				} else {
					this.setState({
						errorFetch: true,
						proceedFetch: false,
						successFetch: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorFetch: true,
					proceedFetch: false,
					successFetch: false
				});
			});

		return true;
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			name,
			integrationModeTypeName,
			clientId,
			crc,
			report,
			proceedUpdate,
			proceedFetch
		} = this.state;
		if (proceedUpdate || proceedFetch) return false;

		if (name === '' || integrationModeTypeName === '' || clientId === '' || crc === '' || report === '') {
			this.setState({
				errorUpdate: true,
				proceedUpdate: false,
				successUpdate: false
			});

			return false;
		}

		const {integrationId} = this.props.match.params;

		const data = {
			name,
			integration_mode_type_name: integrationModeTypeName,
			client_id: clientId,
			crc,
			report
		};

		this.setState({
			errorUpdate: false,
			proceedUpdate: true,
			successUpdate: false
		});

		axios.post(`${process.env.REACT_APP_API_URL}/integration/${integrationId}`, data)
			.then((res) => {
				if (res.success) {
					this.setState({
						errorUpdate: false,
						proceedUpdate: false,
						successUpdate: true
					});

					setTimeout(() => {
						window.location.replace('/integration');
					}, 2000);
				} else {
					this.setState({
						errorUpdate: true,
						proceedUpdate: false,
						successUpdate: false
					});
				}
			})
			.catch(() => {
				this.setState({
					errorUpdate: true,
					proceedUpdate: false,
					successUpdate: false
				});
			});

		return true;
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	render() {
		const {
			initialized,
			successFetch,
			proceedFetch,
			errorFetch,
			successUpdate,
			proceedUpdate,
			errorUpdate,
			name,
			integrationModeTypeName,
			clientId,
			crc,
			report
		} = this.state;
		const {integrationId} = this.props.match.params;

		return (
			<LayoutPrivate>
				<Row>
					<Col md={{span: 8, offset: 2}}>
						<h2 style={{marginBottom: '2rem'}}>
							Edycja integracji #{integrationId}
						</h2>
						{
							errorFetch && (
								<Alert variant="danger">
									Błąd połączenia z serwerem.
								</Alert>
							)
						}
						{
							proceedFetch && (
								<Alert variant="warning">
									Trwa ładowanie danych, daj systemowi trochę czasu :)
								</Alert>
							)
						}
						{
							successFetch && (
								<Alert variant="success">
									Gotowe, możesz pracować :)
								</Alert>
							)
						}
						{
							errorUpdate && (
								<Alert variant="danger">
									Popraw dane w formularzu, gdyż wystąpił błąd.
								</Alert>
							)
						}
						{
							proceedUpdate && (
								<Alert variant="warning">
									Aktualizowanie integracji w toku...
								</Alert>
							)
						}
						{
							successUpdate && (
								<Alert variant="success">
									Integracja została zaktualizowana, trwa przekierowanie...
								</Alert>
							)
						}
						{
							initialized && (
								<Form onSubmit={this.handleSubmit}>
									<Form.Group className="mb-3" controlId="name">
										<Form.Label>Nazwa integracji</Form.Label>
										<Form.Control name="name" defaultValue={name} type="text" placeholder="Wpisz nazwę integracji" onChange={this.handleChange} />
									</Form.Group>

									<Row>
										<Col lg={{span: 6}}>
											<Form.Group className="mb-3" controlId="integrationModeTypeName">
												<Form.Label>Tryb</Form.Label>
												<Form.Control name="integrationModeTypeName" as="select" onChange={this.handleChange}>
													<option value="">Wybierz tryb integracji</option>
													<option value="DEVELOPMENT" selected={integrationModeTypeName === 'DEVELOPMENT'}>DEVELOPMENT</option>
													<option value="PRODUCTION" selected={integrationModeTypeName === 'PRODUCTION'}>PRODUCTION</option>
												</Form.Control>
											</Form.Group>
										</Col>
										<Col lg={{span: 6}}>
											<Form.Group className="mb-3" controlId="client_id">
												<Form.Label>Identyfikator klienta</Form.Label>
												<Form.Control name="clientId" defaultValue={clientId} type="text" placeholder="Wpisz identyfikator klienta" onChange={this.handleChange} />
											</Form.Group>
										</Col>
									</Row>

									<Row>
										<Col lg={{span: 6}}>
											<Form.Group className="mb-3" controlId="crc">
												<Form.Label>Wartość CRC</Form.Label>
												<Form.Control name="crc" defaultValue={crc} type="text" placeholder="Wpisz wartość CRC" onChange={this.handleChange} />
											</Form.Group>
										</Col>
										<Col lg={{span: 6}}>
											<Form.Group className="mb-3" controlId="report">
												<Form.Label>Wartość REPORT</Form.Label>
												<Form.Control name="report" defaultValue={report} type="text" placeholder="Wpisz wartość REPORT" onChange={this.handleChange} />
											</Form.Group>
										</Col>
									</Row>

									<Button variant="primary" type="submit">
										Zapisz integrację
									</Button>
								</Form>
							)
						}
					</Col>
				</Row>
			</LayoutPrivate>
		);
	}
}

EditIntegration.propTypes = {
	match: PropTypes.object.isRequired
};

export default EditIntegration;
